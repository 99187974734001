import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { ifProp } from "styled-tools";

const styles = css`
  display: inline-flex;
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  text-decoration: none;
  cursor: ${ifProp("disabled", "default", "pointer")};
  box-sizing: border-box;
  transition: background-color 250ms ease-out, color 250ms ease-out,
    border-color 250ms ease-out;
  pointer-events: ${ifProp("disabled", "none", "auto")};
  background-color: ${ifProp("altColor", "#707070", "#0039a6")};
  border: 1px solid white;

  border-radius: 7px;
  background-color: #0c55a5;
  font-size: 18px;
  color: #fff;
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(180deg, #0c55a5 0, #094484);

  &:hover,
  &:focus,
  &:active {

    opacity: 1;
    color: white !important;
    background-image: linear-gradient(180deg, #0e66c6 0, #0e5aac);
    text-decoration: none !important;
  }
  &:disabled {
    opacity: 0.4;
  }
  &:focus {
    outline: none;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
    padding: 4px 14px;
  }
`;

const StyledLink = styled(
  ({ disabled, transparent, reverse, palette, height, theme, ...props }) => (
    <Link {...props} />
  )
)`
  ${styles}
`;

const Anchor = styled.a`
  ${styles}
`;
const StyledButton = styled.button`
  ${styles}
`;

const Button = ({ type, ...props }) => {
  if (props.to) {
    return <StyledLink {...props} />;
  } else if (props.href) {
    return <Anchor {...props} />;
  }
  return <StyledButton {...props} type={type} />;
};

Button.propTypes = {
  disabled: PropTypes.bool,
  palette: PropTypes.string,
  transparent: PropTypes.bool,
  reverse: PropTypes.bool,
  height: PropTypes.number,
  type: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
};

Button.defaultProps = {
  palette: "primary",
  type: "button",
  height: 40,
};

export default Button;

import React from "react";
import styled from "styled-components";
import { Row } from "reactstrap";

const NormalRow = styled(Row)`
  border: 1px solid #707070;
  border-top: none;
  font-size: 14px;
  padding: 7px 0px;
  background-color: white;
  @media screen and (max-width: 768px) {
    font-size: 11px;
  }
`;
const NormalRowPadding = styled(NormalRow)`
  padding: 0;
`;
const TableRow = ({ ...props }) => {
  if (props.children || props.dangerouslySetInnerHTML) {
    if (props.background) {
      return <NormalRowPadding {...props}>{props.children}</NormalRowPadding>;
    } else {
      return <NormalRow {...props}>{props.children} </NormalRow>;
    }
  } else {
    return null;
  }
};

export default TableRow;

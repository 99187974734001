import axios from 'axios';
import { toast } from "react-toastify"

var apiUrl = `${process.env.GATSBY_API_URL}/api/`;
/*
const config = {
	responseType: 'arraybuffer',
	headers: {"Access-Control-Allow-Origin": "*"}
}
*/

export const GetResources = (type, tab) => {
	var responses = axios.get(`${apiUrl}Library/GetResources?tab=${tab}&type=${type}`)
		.then (response => {
			return response;
		})
		.catch(e => {
			toast.error("Failed to load data, Please try again!");
			return {err: "error"};
		})
	return responses;
}

export const GetAppDropdownItems = () => {
	var responses = axios.get(`${apiUrl}Library/GetAppDropdownItems`)
		.then (response => {
			return response;
		})
		.catch(e => {
			toast.error("Failed to load data, Please try again!");
			return {err: "error"};
		})
	return responses;
}
export const SearchAppInfo = (data) => {
	var responses = axios.post(`${apiUrl}Library/SearchAppInfo`, data)
		.then (response => {
			return response;
		})
		.catch(e => {
			toast.error("Failed to load data, Please try again!");
			return {err: "error"};
		})
	return responses;
}
































import React from "react";
import styled from "styled-components";

const StyledHR = styled.hr`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Hr = ({ ...props }) => {
  return <StyledHR {...props} />;
};

export default Hr;

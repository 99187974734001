import React from "react";
import styled from "styled-components";

const StyledH1 = styled.h1`
  font-family: "Roboto", "Open Sans", Arial, Helvetica, sans-serif;
  color: #404040;
  font-size: 32px;
  line-height: 50px;
  display: inline-block;
  margin-bottom: 12px;
  font-weight: normal;
  @media only screen and (max-width: 900px) {
    font-size: 24px;
    line-height: 32px;
  }
`;
const H1 = ({ ...props }) => {
  return <StyledH1 {...props}>{props.children}</StyledH1>;
};

export default H1;

import React from "react";
import styled from "styled-components";
import Select from "../../components/atoms/select";
import Button from "../../components/atoms/button";
import { Trans, withTranslation } from "react-i18next";
import { GetAppDropdownItems, SearchAppInfo } from "../../services/library";
import TableRow from "../atoms/tableRow";
import { Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faFilePdf,
  faHistory,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import TableRowHeader from "../atoms/tableRowHeader";
import H1 from "../atoms/h1";
import Hr from "../atoms/hr";

const Container = styled.div``;
const StyledOption = styled.option`
  color: #444;
`;
const StyledSelect = styled(Select)`
  margin-bottom: 20px;
`;

const TableContainer = styled.div`
  margin: 0 15px;
`;

class ApplicationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      selectedPhase: "0",
      phases: [],
      segments: [],
      items: [],
      selectedSegment: "0",
    };
  }

  componentDidMount() {
    GetAppDropdownItems().then((result) => {
      if (!result.err) {
        this.setState({
          phases: result.data.Phases,
          segments: result.data.Segments,
        });
      }
    });
  }

  search = () => {
    this.setState({ loaded: false });
    var obj = {
      Phase: this.state.selectedPhase,
      Segment: this.state.selectedSegment,
    };
    SearchAppInfo(obj).then((result) => {
      if (!result.err) {
        this.setState({
          items: result.data,
          loaded: true,
        });
      }
    });
  };

  handlePhaseChange = (event) => {
    let val = event.target.value;
    this.setState({ selectedPhase: val });
  };

  handleSegmentChange = (event) => {
    let val = event.target.value;
    this.setState({ selectedSegment: val });
  };

  render() {
    const results = this.state.items.map((item, key) => (
      <TableRow key={key}>
        <Col xs="8" md="9" lg="11">
          <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>&nbsp;&nbsp;
          {item.Title}
        </Col>
        <Col xs="4" md="3" lg="1" style={{textAlign: "right"}}>
            <Button style={{padding: "4px 10px"}} rel="noreferrer" target={"_blank"} href={item.Link}> <FontAwesomeIcon icon={faChevronCircleRight}></FontAwesomeIcon>&nbsp;&nbsp;<Trans>View</Trans></Button>
        </Col>
      </TableRow>
    ));
    return (
      <Container>
        <p>
          <Trans>
            Search our database to locate the Application Note of interest.
            Begin your search by selecting one of the following
          </Trans>:
        </p>
        <p>
          <Trans>Phase</Trans>:
        </p>
        <StyledSelect
          onChange={this.handlePhaseChange}
          on
          value={this.state.selectedPhase}
        >
          <StyledOption key="0" value={0}>
          {this.props.t("Choose a Phase")}
          </StyledOption>
          {this.state.phases.length > 0 ? (
            this.state.phases.map((option) => {
              return (
                <StyledOption key={option} value={option}>
                  {option}
                </StyledOption>
              );
            })
          ) : (
            <StyledOption key="-1" value="100" disabled>
               {this.props.t("Waiting on other data to load")}
            </StyledOption>
          )}
        </StyledSelect>
        <p>
          <Trans>Segment</Trans>:
        </p>
        <StyledSelect
          onChange={this.handleSegmentChange}
          on
          value={this.state.selectedSegment}
        >
          <StyledOption key="0" value={0}>
            {this.props.t("Choose a Segment")}
          </StyledOption>
          {this.state.segments.length > 0 ? (
            this.state.segments.map((option2) => {
              return (
                <StyledOption key={option2} value={option2}>
                  {option2}
                </StyledOption>
              );
            })
          ) : (
            <StyledOption key="-1" value="100" disabled>
              Waiting on other data to load
            </StyledOption>
          )}
        </StyledSelect>
        <Button
          disabled={
            this.state.selectedPhase === "0" &&
            this.state.selectedSegment === "0"
          }
          onClick={this.search}
        >
          {" "}
          <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>&nbsp;
          <Trans>Search</Trans>
        </Button>
        {this.state.loaded && (
          <>
            <Hr />
            <H1>
              <FontAwesomeIcon icon={faHistory}></FontAwesomeIcon>&nbsp;
              <Trans>Results</Trans>
            </H1>
            <TableContainer>
              <TableRowHeader>
                <Col xs="8" md="9" lg="11">
                  <Trans>Title</Trans>
                </Col>
                <Col xs="4" md="3" lg="1">
                </Col>
              </TableRowHeader>
              {results}
              {this.state.items.length === 0 && (
                <TableRow>
                  <Col>
                    <Trans>No results have been found</Trans>
                  </Col>
                </TableRow>
              )}
            </TableContainer>
          </>
        )}
      </Container>
    );
  }
}
export default withTranslation()(ApplicationSearch);

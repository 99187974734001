import React from "react";
import styled from "styled-components";
import { Row } from "reactstrap";

const HeaderRow = styled(Row)`
  background-color: ${(props) => props.bgColor || "#003ba8"};
  padding: 10px 5px;
  border-radius: 8px 8px 0 0;
  font-size: 13px;
  color: ${(props) => (props.bgColor === "gold" ? "#474747" : "white")};
  font-weight: bold;
  border: 1px solid #b7b7b7;
  border-bottom: 1px solid #707070;
  background: ${(props) =>
    props.bgColor === "gold"
      ? "repeating-linear-gradient(45deg, rgb(255, 239, 153), rgb(255, 239, 153) 20px, rgb(255, 223, 128) 20px, rgb(255, 223, 128) 40px)"
      : "repeating-linear-gradient(45deg, #003ba8, #003ba8 20px, #0050d4 20px, #0050d4 40px)"};
`;
const TableRowHeader = ({ ...props }) => {
  if (props.children || props.dangerouslySetInnerHTML) {
    return (
      <HeaderRow
        {...props}
        style={{ padding: props.background ? "0px 0px" : "5px 5px" }}
      >
        {props.children}
      </HeaderRow>
    );
  } else {
    return null;
  }
};

export default TableRowHeader;

import React from "react";
import Layout from "../../components/layout"
import Seo from "../../components/atoms/seo";
import { LIBRARY_COLOR } from "../../theme/default";
import H1 from "../../components/atoms/h1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { graphql } from 'gatsby'
import LibraryTabs from "../../components/organisms/libraryTabs";
import Hr from "../../components/atoms/hr";
import { Trans, withTranslation } from "react-i18next";
import ApplicationSearch from "../../components/organisms/applicationSearch";

class Search extends React.Component {
  render() {
    return (
      <Layout color={LIBRARY_COLOR}>
          <Seo title={this.props.t('Application Search')} keywords={[`PerkinElmer`]} />
          <LibraryTabs active={1}></LibraryTabs>
          <Hr/>
         <H1><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon> <Trans>Application Search</Trans></H1>
       <ApplicationSearch></ApplicationSearch>
      </Layout>
    );
  }
}
export default withTranslation()(Search);
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "library"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

